import * as React from "react";
import FeatureScreen1 from "./../assets/image/Feature-screen-1.svg";
import FeatureScreen2 from "./../assets/image/Feature-screen-2.svg";
import FeatureScreen3 from "./../assets/image/Feature-screen-3.svg";

const Features = () => {
  return (
    <>
      <div className="flex flex-row w-full items-center justify-center">
        <div className="flex flex-1 h-0.5 bg-black border mx-12" />
        <h1 className="text-[54px] font-bold text-center">Features</h1>
        <div className="flex flex-1 h-0.5 bg-black border  mx-24" />
      </div>
      <div className="flex bg-white items-center justify-evenly xl:mb-[100px] mb-[75px] flex-col-reverse md:flex-row h-fit w-full 2xl:px-44 xl:px-32 lg:px-[100px] md:px-[75px] sm:px-10 px-5 mt-20">
        <img src={FeatureScreen1} alt="Feature Screen 1" />
        <div className="text-left lg:w-[450px] w-fit mb-[75px] lg:p-5 md:p-5">
          <div className="bg-yellow w-fit p-4 rounded-full mb-[50px]">
            <RefreshIcon />
          </div>
          <h1 className="text-[54px] font-bold leading-tight mb-[30px]">
            <span className="text-yellow">
              Recurring <br />
            </span>
            Reminders
          </h1>
          <p className="text-gray-700">
            We'll never miss a chance to remind you of your important
            activities, so you won’t get stuck worrying about what you’ve
            missed.
          </p>
        </div>
      </div>
      <div className="flex items-center justify-evenly bg-white xl:mb-[100px] mb-[75px] flex-col md:flex-row h-fit w-full 2xl:px-44 xl:px-32 lg:px-[100px] md:px-[75px] sm:px-10 px-5">
        <div className="text-right  lg:w-[450px] w-fit lg:p-5 md:p-5 flex flex-col justify-end items-end mb-[75px]">
          <div className="bg-yellow w-fit p-4 rounded-full mb-[50px] mr-0">
            <OptionsIcon />
          </div>
          <h1 className="text-[54px] font-bold leading-tight mb-[30px]">
            <span className="text-yellow">Options </span>
            to set <br /> Reminders
          </h1>
          <p className="text-gray-700">
            Because we care about you, we provide you with multiple ways to set
            your reminders. You can use your voice or manually set it in the
            App, or you can chat with our bot from WhatsApp, Discord, and
            Telegram and Slack.
          </p>
        </div>
        <img src={FeatureScreen2} alt="Feature Screen 2" />
      </div>
      <div className="flex items-center justify-evenly xl:mb-[100px] mb-[75px] flex-col-reverse md:flex-row h-fit w-full 2xl:px-44 xl:px-32 lg:px-[100px] md:px-[75px] sm:px-10 px-5">
        <img src={FeatureScreen3} alt="Feature Screen 1" />
        <div className="text-left lg:w-[450px] w-fit mb-[75px] lg:p-5 md:p-5">
          <div className="bg-yellow w-fit p-4 rounded-full mb-[50px]">
            <BellIcon />
          </div>
          <h1 className="text-[54px] font-bold leading-tight mb-[30px]">
            Get notified <br /> with
            <span className="text-yellow"> our bots</span>
          </h1>
          <p className="text-gray-700">
            Get notified in your preferred messaging apps - be it Telegram,
            Whatsapp, Discord or Slack.
          </p>
        </div>
      </div>
    </>
  );
};

const RefreshIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.705 15.58H35.025L29.7233 10.275C28.0165 8.56811 25.8903 7.34065 23.5587 6.716C21.227 6.09135 18.772 6.09154 16.4404 6.71653C14.1089 7.34152 11.9829 8.5693 10.2763 10.2764C8.56972 11.9836 7.3426 14.1099 6.71833 16.4417M4.97499 32.74V24.42M4.97499 24.42H13.295M4.97499 24.42L10.275 29.725C11.9819 31.4319 14.108 32.6593 16.4396 33.284C18.7713 33.9086 21.2263 33.9084 23.5579 33.2835C25.8894 32.6585 28.0154 31.4307 29.722 29.7235C31.4286 28.0164 32.6557 25.8901 33.28 23.5583M35.025 7.25999V15.5767"
        stroke="white"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const OptionsIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5 10H33.75M17.5 10C17.5 10.663 17.2366 11.2989 16.7678 11.7678C16.2989 12.2366 15.663 12.5 15 12.5C14.337 12.5 13.7011 12.2366 13.2322 11.7678C12.7634 11.2989 12.5 10.663 12.5 10M17.5 10C17.5 9.33696 17.2366 8.70107 16.7678 8.23223C16.2989 7.76339 15.663 7.5 15 7.5C14.337 7.5 13.7011 7.76339 13.2322 8.23223C12.7634 8.70107 12.5 9.33696 12.5 10M12.5 10H6.25M17.5 30H33.75M17.5 30C17.5 30.663 17.2366 31.2989 16.7678 31.7678C16.2989 32.2366 15.663 32.5 15 32.5C14.337 32.5 13.7011 32.2366 13.2322 31.7678C12.7634 31.2989 12.5 30.663 12.5 30M17.5 30C17.5 29.337 17.2366 28.7011 16.7678 28.2322C16.2989 27.7634 15.663 27.5 15 27.5C14.337 27.5 13.7011 27.7634 13.2322 28.2322C12.7634 28.7011 12.5 29.337 12.5 30M12.5 30H6.25M27.5 20H33.75M27.5 20C27.5 20.663 27.2366 21.2989 26.7678 21.7678C26.2989 22.2366 25.663 22.5 25 22.5C24.337 22.5 23.7011 22.2366 23.2322 21.7678C22.7634 21.2989 22.5 20.663 22.5 20M27.5 20C27.5 19.337 27.2366 18.7011 26.7678 18.2322C26.2989 17.7634 25.663 17.5 25 17.5C24.337 17.5 23.7011 17.7634 23.2322 18.2322C22.7634 18.7011 22.5 19.337 22.5 20M22.5 20H6.25"
        stroke="white"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const BellIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.7617 28.47C27.867 28.1023 30.9181 27.3695 33.8517 26.2867C31.3673 23.5347 29.9945 19.9575 30 16.25V15.0833V15C30 12.3478 28.9464 9.8043 27.0711 7.92893C25.1957 6.05357 22.6522 5 20 5C17.3478 5 14.8043 6.05357 12.9289 7.92893C11.0536 9.8043 10 12.3478 10 15V16.25C10.005 19.9577 8.63164 23.5349 6.14666 26.2867C9.035 27.3533 12.08 28.095 15.2383 28.47M24.7617 28.47C21.5983 28.8452 18.4017 28.8452 15.2383 28.47M24.7617 28.47C25.0018 29.2198 25.0616 30.0157 24.936 30.7929C24.8104 31.5701 24.503 32.3067 24.039 32.9426C23.5749 33.5786 22.9672 34.096 22.2653 34.4527C21.5635 34.8094 20.7873 34.9953 20 34.9953C19.2127 34.9953 18.4365 34.8094 17.7347 34.4527C17.0328 34.096 16.4251 33.5786 15.961 32.9426C15.4969 32.3067 15.1896 31.5701 15.064 30.7929C14.9384 30.0157 14.9982 29.2198 15.2383 28.47M5.20667 12.5C5.67661 9.70828 6.92966 7.10741 8.82 5M31.18 5C33.0703 7.10741 34.3234 9.70828 34.7933 12.5"
        stroke="white"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default Features;
