import AppleBadge from "./../assets/image/apple-badge.svg";
import GoogleBadge from "./../assets/image/google-badge.svg";

const AppLinks = () => {
  return (
    <div className="flex items-center">
      <a
        href="https://apps.apple.com/app/id1667093471"
        target={"_blank"}
        rel="noreferrer"
      >
        <img
          className="border-2 rounded-2xl"
          src={AppleBadge}
          alt="Apple Badge"
          width={"130px"}
        />
      </a>
      <a
        className="ml-3"
        href="https://play.google.com/store/apps/details?id=app.iwontforget"
        target={"_blank"}
        rel="noreferrer"
      >
        <img
          className="border-2 rounded-2xl"
          src={GoogleBadge}
          alt="Google Badge"
          width={"130px"}
        />
      </a>
    </div>
  );
};

export default AppLinks;
