import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

// PAGES ARE HERE
import Home from "./home/Home";
import PrivacyPolicy from "./privacy/Privacy";
import TermsOfUse from "./termsOfUse/TermsOfUse";
import DeleteAccount from "./deleteAccount/DeleteAccount";
import Credits from "./credits/Credits";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/privacy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/terms",
    element: <TermsOfUse />,
  },
  {
    path: "/credits",
    element: <Credits />,
  },
  {
    path: "/deleteAccount",
    element: <DeleteAccount />,
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
