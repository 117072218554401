import TBB from "./../assets/image/TBB.png";

const About = () => {
  return (
    <div className="border-b">
      <div className="bg-white flex items-center justify-center lg:py-6 py-18 px-5 xl:flex-row lg:flex-col-reverse md:flex-col-reverse flex-col-reverse xl:h-[500px] h-fit w-full 2xl:px-44 xl:px-32 lg:px-[100px] md:px-[75px] sm:px-10 px-5">
        <div className="xl:w-[533px] w-full text-center">
          <div className="flex flex-row items-center justify-center mb-4">
            <p className="text-gray-700 text-lg">Made with ❤️ by</p>
            <div className="flex">
              <a
                href="https://teamblackbox.in/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={TBB} className="mx-2 w-8" alt="Team Black Box Logo" />
              </a>
            </div>
          </div>
          <h1 className="font-light text-[64px] leading-[70px] mb-5 text-center">
            <span className="font-bold text-yellow">Build </span>the
            <span className="font-bold text-yellow"> Amaze</span>
          </h1>
          <p className="text-lg mb-12 text-center">
            <span className="font-bold">I Won't Forget</span> was built in the
            second ever 48 hour monthly hackathon at{" "}
            <a
              href="https://teamblackbox.in"
              target="_blank"
              className="underline"
              rel="noreferrer"
            >
              Team Black Box
            </a>{" "}
            by our interns, alumni and us (The co-founders) on 21st & 22nd
            November 2022.
          </p>
          <a
            href="https://teamblackbox.in"
            target="_blank"
            rel="noreferrer"
            className="rounded-[20px] text-white text-base font-semibold mb-[20px] py-[15px] px-[24px] bg-black border-yellow border-4"
          >
            Official TBB Website
          </a>
        </div>
      </div>
    </div>
  );
};

export default About;
