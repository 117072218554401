import * as React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

const Credits = () => {
  return (
    <>
      <Navbar />
      <div className="xl:my-[100px] my-[75px] lg:px-[100px] md:px-[75px] sm:px-10 px-5 text-center">
        <div className="mb-5">
          <h1 className="text-2xl font-bold my-5">Developers</h1>
          <p className="block hover:bg-yellow rounded-3xl w-fit mx-auto p-1">
            Subramanian Elavathur - Co-Founder TBB & Oak
          </p>
          <p className="block hover:bg-yellow rounded-3xl w-fit mx-auto p-1">
            Aditya Shetty - Co-Founder TBB & Oak
          </p>
          <p className="block hover:bg-yellow rounded-3xl w-fit mx-auto p-1">
            Shruthi Abirami P - Intern #0 TBB 2022.2
          </p>
          <p className="block hover:bg-yellow rounded-3xl w-fit mx-auto p-1">
            Aditya Vardhan - Intern #1 TBB 2022.2
          </p>
          <p className="block hover:bg-yellow rounded-3xl w-fit mx-auto p-1">
            Ekaksh Janweja - Intern #2 TBB 2023.1
          </p>
          <p className="block hover:bg-yellow rounded-3xl w-fit mx-auto p-1">
            Suparv Lakhanpal - Intern #3 TBB 2023.1
          </p>
          <p className="block hover:bg-yellow rounded-3xl w-fit mx-auto p-1">
            Siftee Ratra - Intern #4 TBB 2023.1
          </p>
          <p className="block hover:bg-yellow rounded-3xl w-fit mx-auto p-1">
            Yahaya M. Bello - Intern #5 TBB 2023.1
          </p>
          <p className="block hover:bg-yellow rounded-3xl w-fit mx-auto p-1">
            Kalp Adhwaryu - Intern #6 TBB 2023.1
          </p>
        </div>
        <div className="mb-5">
          <h1 className="text-2xl font-bold my-5">Icons</h1>
          <a
            className="block hover:bg-yellow rounded-3xl w-fit mx-auto p-1"
            href="https://icons8.com/icon/GgyRdUL5k1fr/android-os"
          >
            Android OS SVG - Icon8
          </a>
          <a
            className="block hover:bg-yellow rounded-3xl w-fit mx-auto p-1"
            href="https://icons8.com/icon/30840/apple-logo"
          >
            IOS OS SVG - Icon8
          </a>
          <a
            className="block hover:bg-yellow rounded-3xl w-fit mx-auto p-1"
            href="https://www.freepnglogos.com/pics/google-logo-history-png"
          >
            Google Play icons created by Free PNG Logos - Freepnglogos
          </a>
          <a
            className="block hover:bg-yellow rounded-3xl w-fit mx-auto p-1"
            href="https://www.freepnglogos.com/uploads/app-store-logo-png/download-on-the-app-store-logo-png-23.png"
          >
            Apple Store created by Free PNG Logos - Freepnglogos
          </a>
        </div>
        <div className="mb-5">
          <h1 className="text-2xl font-bold my-5">
            <a href="https://www.openpeeps.com/">Open Peeps Characters</a>
          </h1>

          <a
            className="block hover:bg-yellow rounded-3xl w-fit mx-auto p-1"
            href="https://assets.website-files.com/5e51c674258ffe10d286d30a/5e535b1d67293aaf6b5e7a33_peep-81.svg"
          >
            Co-founder 2 Peep Character - Open Peeps
          </a>
          <a
            className="block hover:bg-yellow rounded-3xl w-fit mx-auto p-1"
            href="https://assets.website-files.com/5e51c674258ffe10d286d30a/5e535a83d3992372c25556b9_peep-76.svg"
          >
            Intern #2 Peep Character - Open Peeps
          </a>
          <a
            className="block hover:bg-yellow rounded-3xl w-fit mx-auto p-1"
            href="https://assets.website-files.com/5e51c674258ffe10d286d30a/5e535c92c67e790fd496656f_peep-94.svg"
          >
            Intern #3 Peep Character - Open Peeps
          </a>
          <a
            className="block hover:bg-yellow rounded-3xl w-fit mx-auto p-1"
            href="https://assets.website-files.com/5e51c674258ffe10d286d30a/5e5357fcc99250d0c6c8a111_peep-54.svg"
          >
            Intern #6 Peep Character - Open Peeps
          </a>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Credits;
