export const GOLDFISH_FACTS: GoldFishFacts[] = [
  {
    title: "Having a memory like a goldfish is a good thing.",
    fact: "It has been shown that goldfish have better memories than we first thought. They can recognise and remember objects even after some time has passed. For example, if you showed your goldfish a red small building block before you feed them and keep doing that, they will begin to remember that the red block means it’s dinner time. ",
  },
  {
    title: "Goldfish aren’t all the same size.",
    fact: "The biggest goldfish ever recorded was 18.7 inches long and the smallest type of goldfish is the Twisty Tailed goldfish which measures 6 inches in length. ",
  },
  {
    title: "Not all Goldfish are gold",
    fact: "In fact, they come in a variety of different colours from grey/brown to even silver and white!",
  },
  {
    title: "Goldfish can’t taste anything.",
    fact: " As they have no barbels (a sensory organ that helps fish taste food) they have no idea what their food tastes like - no wonder goldfish food can smell that bad!",
  },
  {
    title: "Goldfish can live up to 15 years. ",
    fact: "If they are kept in great conditions, goldfish can live a very long time in an aquarium. In an outside pond, it has been reported that they can live from 25-40 years old!",
  },
  {
    title: "Goldfish don’t have a stomach.",
    fact: "Goldfish do not have stomachs to store their food! So they need to eat small amounts (but often) to help keep them fed and happy.",
  },
  {
    title: "Goldfish don’t have any eyelids.",
    fact: "If you thought you’ve seen a goldfish wink at you then you were sadly wrong. Goldfish can’t blink or close their eyes, that’s why they need the light turned off to be able to go to sleep.",
  },
  {
    title: "Goldfish can smell.",
    fact: "In fact, they come in a variety of different colours from grey/brown to even silver and white!",
  },
  {
    title: "Goldfish tails and fins can grow back.",
    fact: "Unless the tail and fin have been completely ripped off, a goldfish is able to heal an injured tail and fin back to normal.!",
  },
  {
    title: "Female goldfish lay up to 1000 eggs at a time.",
    fact: "Don’t worry, not all of them do. Some get eaten before they hatch, while others are never fertilised and could never become a goldfish. However, many still do and that is why if you have one male and one female goldfish, you may end up with lots and lots of little goldfish!",
  },
];

export interface GoldFishFacts {
  title: string;
  fact: string;
}
