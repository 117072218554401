import Logo from "./../assets/image/logo.png";
import AppLinks from "./AppLinks";

const Footer = () => {
  return (
    <>
      <div className="flex justify-between  bg-white xl:items-center items-start xl:flex-row lg:flex-col-reverse flex-col-reverse xl:h-[470px] lg:h-fit md:h-fit h-fit py-20 2xl:px-44 xl:px-32 lg:px-[100px] md:px-[75px] sm:px-10 py-5 px-5">
        <div className="xl:mb-0 mb-10">
          <div className="flex items-center mb-[50px]">
            <img
              src={Logo}
              style={{ width: "40px", height: "40px" }}
              className="mr-2"
              alt="I Wont' Forget Logo"
            />
            <span className="font-bold text-[24px] text-Dark_Blue">
              I Won't Forget
            </span>
          </div>
          <p className="font-medium text-xl mb-2 text-Dark_Blue">
            Download the app by clicking the link below :
          </p>
          <AppLinks />
        </div>
        <div className="xl:mb-0 mb-10">
          <ul className="list-none">
            <p className="font-bold mb-[16px] text-[24px] text-Dark_Blue">
              Links
            </p>
            <li className="mb-[12px] text-base font-medium text-Dark_Blue">
              <a href="/">Home</a>
            </li>
            <li className="mb-[12px] text-base font-medium text-Dark_Blue">
              <a href="/credits">Credits</a>
            </li>
            <li className="mb-[12px] text-base font-medium text-Dark_Blue">
              <a href="/privacy">Privacy Policy</a>
            </li>
            <li className="mb-[12px] text-base font-medium text-Dark_Blue">
              <a href="/terms">Terms & Conditions</a>
            </li>
            <li className="mb-[12px] text-base font-medium text-Dark_Blue">
              <a href="/deleteAccount">Request User Account Deletion</a>
            </li>
          </ul>
        </div>
      </div>
      <div className="w-full text-center bg-white pb-4">
        <span className="font-semibold">
          © TeamBlackBox Private Limited 2022 <br />
        </span>
      </div>
    </>
  );
};

export default Footer;
