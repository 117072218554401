import * as React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import TermsBody from "./TermsBody";

const TermsOfUse = () => {
  return (
    <div>
      <Navbar />
      <TermsBody />
      <Footer />
    </div>
  );
};

export default TermsOfUse;
