import * as React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import PrivacyBody from "./PrivacyBody";

const PrivacyPolicy = () => {
  return (
    <div>
      <Navbar />
      <PrivacyBody />
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
