import React from "react";
import Logo from "./../assets/image/logo.png";
import AppLinks from "./AppLinks";

const Navbar = () => {
  return (
    <nav className="flex bg-white dark:bg-slate-800  items-center justify-between h-20 w-full 2xl:px-44 xl:px-32 lg:px-[100px] md:px-[75px] sm:px-10 py-5 px-5">
      <a href="/" className="brand flex items-center">
        <img
          className="mr-4 dark:border-slate-400 border-transparent border-2 rounded-2xl"
          src={Logo}
          style={{ width: "50px" }}
          alt="I Won't Forget Logo"
        />
        <span className="font-bold text-[24px] text-black dark:text-slate-200">
          I Won't Forget
        </span>
      </a>
      <div className="md:shrink">
        <AppLinks />
      </div>
    </nav>
  );
};

export default Navbar;
