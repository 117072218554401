import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

const PrivacyPolicy = () => {
  return (
    <div>
      <Navbar />
      <div className="flex bg-white items-center justify-between xl:mb-[100px] mb-[75px] lg:py-12 py-36 px-5 flex-col md:flex-row h-fit w-full 2xl:px-44 xl:px-32 lg:px-[100px] md:px-[75px] sm:px-10 px-5">
        <div className="lg:w-[400px] md:w-[370px] w-fit">
          <h1 className="text-[48px] font-bold mb-[20px]">Delete Account</h1>
          <iframe
            title="Delete Account Form"
            src="https://docs.google.com/forms/d/e/1FAIpQLSeyoQNJUdjpSdXg6fScieE5iOPzeI_VSApGqkUbgz1H6JTqeQ/viewform?embedded=true"
            width="640"
            height="853"
          >
            Loading…
          </iframe>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
