import { useState } from "react";
import JustTheFish from "./../assets/image/just_the_fish.svg";
import Android from "./../assets/image/Android.svg";
import IOS from "./../assets/image/IOS.svg";
import { GOLDFISH_FACTS } from "../constants/goldfishFacts";

const Hero = () => {
  const [factIndex] = useState(0);
  return (
    <div className="flex bg-white items-center justify-between px-5 flex-col md:flex-row h-fit w-full 2xl:px-44 xl:px-32 lg:px-[100px] md:px-[75px] sm:px-10">
      <div className="lg:w-[400px] md:w-[370px] w-fit">
        <h1 className="text-5xl font-bold mb-2 leading-[1.3]">
          <span className="text-yellow">Beautiful </span> and{" "}
          <span className="text-yellow">Reliable </span>Reminders
        </h1>
        <p className="font-light text-base mb-5">
          Never Miss a Beat with I Won't Forget!
        </p>
        <div className="flex w-fit">
          <button className="rounded-[20px] text-white text-base font-semibold mb-[20px] p-[15px] mr-5 bg-yellow border-yellow border-2">
            <a
              href="https://play.google.com/store/apps/details?id=app.iwontforget"
              target={"_blank"}
              rel="noreferrer"
            >
              <img src={Android} className="w-[30px]" alt="Android app" />
            </a>
          </button>
          <button className="rounded-[20px] text-white text-base font-semibold mb-[20px] p-[15px] mr-5 bg-yellow border-yellow border-2">
            <a
              href="https://apps.apple.com/app/id1667093471"
              target={"_blank"}
              rel="noreferrer"
            >
              <img src={IOS} className="w-[30px]" alt="iOS app" />
            </a>
          </button>
        </div>
      </div>
      <div className="flex flex-col w-1/2 h-fit">
        <img src={JustTheFish} className="flex flex-1" alt="Android app" />
        <div className="flex flex-col border-2 rounded-xl p-2 absolute bg-yellow  opacity-0">
          <h1 className="text-center font-bold text-sm underline">
            Goldfish facts
          </h1>
          <h2 className="text-center font-bold text-sm">
            {GOLDFISH_FACTS[factIndex].title}
          </h2>
          <p className="text-center text-sm">
            {GOLDFISH_FACTS[factIndex].fact}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Hero;
